import { BrandBlacklistComponent } from './admin/components/brand-blacklist/brand-blacklist.component';
import { MarkItemComponent } from './admin/components/tecdoc-car-site/mark-car-site/mark-item/mark-item.component';
import { FileSelectComponent } from './admin/components/common/file-select/file-select.component';
import { UserItemComponent } from './admin/components/user-site/user-item/user-item.component';
import { OrderItemComponent } from './admin/components/orders-site/order-item/order-item.component';
import { PriceLevelItemComponent } from './admin/components/price-level-site/price-level-item/price-level-item.component';
import { PriceLevelSiteService } from './admin/components/price-level-site/price-level.service';
import { PriceLevelSiteComponent } from './admin/components/price-level-site/price-level-site.component';
import { PhoneCountrySiteComponent } from './admin/components/phone-country-site/phone-country-site.component';
import { StoreSiteService } from './admin/components/store-site/store-site.service';
import { StoreSiteItemComponent } from './admin/components/store-site/store-item/store-item.component';
import { PageSiteItemComponent } from './admin/components/page-site/page-site-item/page-site-item.component';
import { DownloadInfoItemComponent } from './admin/components/downloadinfo-site/downloadinfo-files-site/downloadinfo-item/downloadinfo-item.component';
import { DownloadinfoSiteCategoryComponent } from './admin/components/downloadinfo-site/downloadinfo-site-category/downloadinfo-site-category.component';
import { DownloadInfoSiteService } from './admin/components/downloadinfo-site/downloadinfo-site.service';
import { PartnersSiteItemComponent } from './admin/components/partners-site/partners-site-item/partners-site-item.component';
import { PartnerSiteSerivce } from './admin/components/partners-site/partner-site.service';
import { StoSiteItemComponent } from './admin/components/sto-site/sto-site-item/sto-site-item.component';
import { ContactSiteItemComponent } from './admin/components/contactus-site/contact-site-item/contact-site-item.component';
import { ColorPickerModule } from 'ngx-color-picker';
import { NewsSiteItemComponent } from './admin/components/news-site/news-site-item/news-site-item.component';
import { EcommerceModule } from './main/apps/e-commerce/e-commerce.module';
import { HomeSiteComponent } from './admin/components/home-site/home-site.component';
import { SettingSiteComponent } from './admin/components/setting-site/setting-site.component';
import { LoginModule } from './main/pages/authentication/login/login.module';
import { ToolbarModule } from './layout/components/toolbar/toolbar.module';
import { QuickPanelModule } from './layout/components/quick-panel/quick-panel.module';
import { VerticalLayout1Component } from './layout/vertical/layout-1/layout-1.component';
import { ChatPanelModule } from './layout/components/chat-panel/chat-panel.module';
import { NavbarVerticalStyle1Module } from './layout/components/navbar/vertical/style-1/style-1.module';
import { NavbarHorizontalStyle1Module } from './layout/components/navbar/horizontal/style-1/style-1.module';
import { LoginComponent } from './main/pages/authentication/login/login.component';
import { JwtInterceptor } from './admin/services/authentication/jwt.interceptor';
import { environment } from 'environments/environment';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatButtonModule, MatIconModule, MatPaginatorIntl } from '@angular/material';
import { InMemoryWebApiModule } from 'angular-in-memory-web-api';
import { TranslateModule } from '@ngx-translate/core';
import 'hammerjs';

import { FuseModule } from '@fuse/fuse.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseProgressBarModule, FuseSidebarModule, FuseThemeOptionsModule } from '@fuse/components';

import { fuseConfig } from 'app/fuse-config';

import { ToastrModule } from 'ngx-toastr';
import { NOTYF, notyfFactory } from './admin/models/notyf.token';

import { FileSaverModule } from 'ngx-filesaver';

import { FakeDbService } from 'app/fake-db/fake-db.service';
import { AppComponent } from 'app/app.component';
import { AppStoreModule } from 'app/store/store.module';
import { LayoutModule } from 'app/layout/layout.module';
import {API_BASE_URL, Client} from './service/Client';
import { AdminBaseService } from './admin/services/admin.base.service';

import { CKEditorModule } from '@ckeditor/ckeditor5-angular';

import { ADMIN_ROUTES } from './admin/components/admin/admin.routes';

// admin components
import { AdminComponent } from './admin/components/admin/admin.component';
import { AuthService } from './admin/services/authentication/auth.service.service';
import { TokenInterceptor } from './admin/services/authentication/token.interceptor';
import { AuthGuard } from './admin/guards/auth.guard';
import { HasPermissionDirective } from './admin/derectives/has-permission.derective';
import { DemoMaterialModule } from './admin/material-module';
import { ChatSiteComponent } from './admin/components/chat-site/chat-site.component';
import { ContactusSiteComponent } from './admin/components/contactus-site/contactus-site.component';
import { DownloadInfoSiteComponent } from './admin/components/downloadinfo-site/downloadinfo-site.component';
import { NewsSiteComponent } from './admin/components/news-site/news-site.component';
import { OrdersSiteComponent } from './admin/components/orders-site/orders-site.component';
import { PartnersSiteComponent } from './admin/components/partners-site/partners-site.component';
import { SliderSiteComponent } from './admin/components/slider-site/slider-site.component';
import { StoSiteComponent } from './admin/components/sto-site/sto-site.component';
import { NewsSiteSerivce } from './admin/components/news-site/news-site.service';
import { SliderSiteService } from './admin/components/slider-site/slider-site.service';
import { SliderSiteItemComponent } from './admin/components/slider-site/slider-site-item/slider-site-item.component';
import { ContactusSiteSerivce } from './admin/components/contactus-site/contactus.site.service';
import { CustomMatPaginatorIntl } from './admin/services/custom-paginator.configuration';
import { OrderSiteSerivce } from './admin/components/orders-site/orders.site.service';
import { StoSiteSerivce } from './admin/components/sto-site/sto-site.service';
import { DownloadinfoFileSiteComponent } from './admin/components/downloadinfo-site/downloadinfo-files-site/downloadinfo-files-site.component';
import { DownloadInfoFileService } from './admin/components/downloadinfo-site/downloadinfo-files-site/downloadinfo-files.service';
import { PageSiteComponent } from './admin/components/page-site/page-site.component';
import { PageSiteSerivce } from './admin/components/page-site/page-site.servoce';
import { ChatSiteService } from './admin/components/chat-site/chat-site.service';
import { ChatStartSiteComponent } from './admin/components/chat-site/start-site/start-site.component';
import { ChatViewSiteComponent } from './admin/components/chat-site/view-site/view-site.component';
import { ChatRightSiteComponent } from './admin/components/chat-site/sidenavs/right-site/right-site.component';
import { ChatContactSiteComponent } from './admin/components/chat-site/sidenavs/right-site/contact-site/contact-site.component';
import { ChatLeftSiteComponent } from './admin/components/chat-site/sidenavs/left-site/left-site.component';
import { ChatsSiteComponent } from './admin/components/chat-site/sidenavs/left-site/chats-site/chats-site.component';
import { ChatUserSiteComponent } from './admin/components/chat-site/sidenavs/left-site/user-site/user-site.component';
import { StoreSiteComponent } from './admin/components/store-site/store-site.component';
import { CountrySiteItemComponent } from './admin/components/country-site/country-item/country-item.component';
import { CountrySiteComponent } from './admin/components/country-site/country-site.component';
import { CitySiteItemComponent } from './admin/components/city-site/city-item/city-item.component';
import { CitySiteComponent } from './admin/components/city-site/city-site.component';
import { CitySiteService } from './admin/components/city-site/city-site.service';
import { CountrySiteService } from './admin/components/country-site/country-site.service';
import { UnitSiteItemComponent } from './admin/components/unit-site/unit-item/unit-item.component';
import { UnitSiteComponent } from './admin/components/unit-site/unit-site.component';
import { UnitSiteService } from './admin/components/unit-site/unit-site.service';
import { OptionSiteItemComponent } from './admin/components/option-site/option-item/option-item.component';
import { OptionSiteComponent } from './admin/components/option-site/option-site.component';
import { OptionSiteService } from './admin/components/option-site/option-site.service';
import { OptionValueSiteComponent } from './admin/components/option-site/option-item/value-site/value-site.component';
import { OptionValueSiteItemComponent } from './admin/components/option-site/option-item/value-item/value-item.component';
import { BrandSiteComponent } from './admin/components/brand-site/brand-site.component';
import { BrandSiteItemComponent } from './admin/components/brand-site/brand-item/brand-item.component';
import { BrandSiteService } from './admin/components/brand-site/brand-site.service';
import { CategorySiteComponent } from './admin/components/category-site/category-site.component';
import { CategorySiteItemComponent } from './admin/components/category-site/category-item/category-item.component';
import { CategorySiteService } from './admin/components/category-site/category-site.service';
import { ProductSiteComponent } from './admin/components/product-site/product-site.component';
import { ProductSiteService } from './admin/components/product-site/product-site.service';
import { ProductSiteItemComponent } from './admin/components/product-site/product-item/product-item.component';
import { SectorItemComponent } from './admin/components/sector-site/sector-item/sector-item.component';
import { SectorSiteComponent } from './admin/components/sector-site/sector-site.component';
import { RouteSiteComponent } from './admin/components/route-site/route-site.component';
import { RouteItemComponent } from './admin/components/route-site/route-item/route-item.component';
import { RouteSiteSerivce } from './admin/components/route-site/route.service';
import { SectorSiteSerivce } from './admin/components/sector-site/sector.service';
import { UserSiteComponent } from './admin/components/user-site/user-site.component';
import { UserSiteService } from './admin/components/user-site/user-site.service';
import { ModificationItemComponent } from './admin/components/tecdoc-car-site/modification-car-site/modification-item/modification-item.component';
import { MarkCarSiteComponent } from './admin/components/tecdoc-car-site/mark-car-site/mark-car-site.component';
import { ModelItemComponent } from './admin/components/tecdoc-car-site/model-car-site/model-item/model-item.component';
import { ModelCarSiteComponent } from './admin/components/tecdoc-car-site/model-car-site/model-car-site.component';
import { ModificationCarSiteComponent } from './admin/components/tecdoc-car-site/modification-car-site/modification-car-site.component';
import { MarkCarService } from './admin/components/tecdoc-car-site/mark-car-site/mark-car.service';
import { ModelCarService } from './admin/components/tecdoc-car-site/model-car-site/model-car.service';
import { ModificationCarService } from './admin/components/tecdoc-car-site/modification-car-site/modification-car.service';
import { ConfirmDialogComponent } from './admin/components/news-site/confirm-dialog/confirm-dialog.component';
import { FileManagerService } from './admin/services/file-manager.service';


const appRoutes: Routes = [
    {
        path: 'admin',
        component: AdminComponent,
        children: ADMIN_ROUTES
    },
    { path: 'login', component: LoginComponent, pathMatch: 'full' },
    // {
    //     path: 'apps',
    //     loadChildren: './main/apps/apps.module#AppsModule'
    // },
    // {
    //     path: 'pages',
    //     loadChildren: './main/pages/pages.module#PagesModule'
    // },
    // {
    //     path: 'ui',
    //     loadChildren: './main/ui/ui.module#UIModule'
    // },
    // {
    //     path: 'documentation',
    //     loadChildren: './main/documentation/documentation.module#DocumentationModule'
    // },
    // {
    //     path: 'angular-material-elements',
    //     loadChildren: './main/angular-material-elements/angular-material-elements.module#AngularMaterialElementsModule'
    // },
    {
        path: '**',
        redirectTo: 'admin'
    }
];

@NgModule({
    declarations: [
        AppComponent,
        AdminComponent,
        SettingSiteComponent,
        HasPermissionDirective,
        ContactusSiteComponent,
        DownloadInfoSiteComponent,
        NewsSiteComponent,
        OrdersSiteComponent,
        PartnersSiteComponent,
        SettingSiteComponent,
        SliderSiteComponent,
        SliderSiteItemComponent,
        StoSiteComponent,
        HomeSiteComponent,
        NewsSiteItemComponent,
        ContactSiteItemComponent,
        StoSiteItemComponent,
        PartnersSiteItemComponent,
        DownloadinfoSiteCategoryComponent,
        DownloadinfoFileSiteComponent,
        DownloadInfoItemComponent,
        PageSiteComponent,
        PageSiteItemComponent,
        ChatSiteComponent,
        ChatStartSiteComponent,
        ChatViewSiteComponent,
        ChatRightSiteComponent,
        ChatContactSiteComponent,
        ChatLeftSiteComponent,
        ChatsSiteComponent,
        ChatUserSiteComponent,
        StoreSiteComponent,
        StoreSiteItemComponent,
        CountrySiteItemComponent,
        CountrySiteComponent,
        CitySiteItemComponent,
        CitySiteComponent,
        UnitSiteItemComponent,
        UnitSiteComponent,
        OptionSiteItemComponent,
        OptionSiteComponent,       
        OptionValueSiteComponent,
        OptionValueSiteItemComponent,
        BrandSiteComponent,
        BrandSiteItemComponent,
        CategorySiteComponent,
        CategorySiteItemComponent,
        ProductSiteComponent,
        ProductSiteItemComponent,
        PhoneCountrySiteComponent,
        PriceLevelSiteComponent,
        PriceLevelItemComponent,
        SectorItemComponent,
        SectorSiteComponent,
        RouteSiteComponent,
        RouteItemComponent,
        OrderItemComponent,
        UserSiteComponent,
        UserItemComponent,
        FileSelectComponent,
        MarkItemComponent,
        MarkCarSiteComponent,
        ModelItemComponent,
        ModelCarSiteComponent,
        ModificationItemComponent,
        ModificationCarSiteComponent,
        BrandBlacklistComponent,
        ConfirmDialogComponent

    ],
    imports: [    
        FileSaverModule, 
        CKEditorModule,  
        ColorPickerModule,
        NavbarHorizontalStyle1Module,
        NavbarVerticalStyle1Module,
        LoginModule,
        ChatPanelModule,
        DemoMaterialModule,
        QuickPanelModule,
        ToolbarModule,
        BrowserModule,
        EcommerceModule,
        BrowserAnimationsModule,
        HttpClientModule,
        RouterModule.forRoot(appRoutes),
        ToastrModule.forRoot(),

        TranslateModule.forRoot(),
        InMemoryWebApiModule.forRoot(FakeDbService, {
            delay: 0,
            passThruUnknownUrl: true
        }),

        // Material moment date module
        MatMomentDateModule,

        // Material
        MatButtonModule,
        MatIconModule,

        // Fuse modules
        FuseModule.forRoot(fuseConfig),
        FuseProgressBarModule,
        FuseSharedModule,
        FuseSidebarModule,
        FuseThemeOptionsModule,

        // App modules
        LayoutModule,
        AppStoreModule
    ],
    providers: [
        {provide: API_BASE_URL, useValue: environment.apiUrl},
        {provide: MatPaginatorIntl, useClass: CustomMatPaginatorIntl},
        AuthService,
        {
            provide: 'LOCALSTORAGE',
            useFactory: getLocalStorage
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: JwtInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptor,
            multi: true
        },
        { provide: NOTYF, useFactory: notyfFactory },
        Client,
        NewsSiteSerivce,
        SliderSiteService,
        ContactusSiteSerivce,
        OrderSiteSerivce,
        AdminBaseService,
        StoSiteSerivce,
        PartnerSiteSerivce,
        DownloadInfoSiteService,
        DownloadInfoFileService,
        PageSiteSerivce,
        ChatSiteService,
        StoreSiteService,
        CitySiteService,
        CountrySiteService,
        UnitSiteService,
        OptionSiteService,
        BrandSiteService,
        CategorySiteService,
        ProductSiteService,
        PriceLevelSiteService,
        RouteSiteSerivce,
        SectorSiteSerivce,
        UserSiteService,
        MarkCarService,
        ModelCarService,
        ModificationCarService,
        AuthGuard,
        FileManagerService
    ],
    bootstrap: [
        AppComponent
    ],
    entryComponents: [
        ConfirmDialogComponent
    ],
})
export class AppModule {
    constructor() {
        // Add an icon to the library for convenient access in other components
        //library.add(faCoffee);
      }
}

// tslint:disable-next-line:typedef
export function getLocalStorage() {
    return (typeof window !== 'undefined') ? window.localStorage : null;
}
